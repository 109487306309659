<template>
    <Menu actual="/material-didactico" class="mt-6" />

	<div class="bg-white overflow-hidden">
		<div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
			<div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
			<div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
				<div>
					<h2 class="text-base text-morado font-semibold tracking-wide uppercase">5 a 8 años</h2>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-vino sm:text-4xl">Yo me quiero yo me cuido</h3>
				</div>
			</div>
			<div class="mt-6 lg:grid lg:grid-cols-2 lg:gap-8">
				<div class="relative lg:row-start-1 lg:col-start-2">
					<svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
						<defs>
							<pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
								<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
							</pattern>
						</defs>
						<rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
					</svg>
					<div class="relative text-base mx-auto max-w-prose lg:max-w-none">
						<figure>
							<div>
								<img class="rounded-lg shadow-lg max-h-96 md:max-h-screen mx-auto" :src="require('@/assets/material-didactico/portada-1.jpg')" alt="Yo me quiero yo me cuido" />
							</div>
						</figure>
					</div>

					<div class="mt-12 hidden md:flex justify-around">
						<router-link to="/material-didactico/como-soy">
							<img class="rounded-lg shadow-lg h-56 md:h-64 mx-auto" :src="require('@/assets/material-didactico/portada-2.jpg')" alt="¿Cómo soy?" />
						</router-link>
						<router-link to="/material-didactico/una-nueva-vida">
							<img class="rounded-lg shadow-lg h-56 md:h-64 mx-auto" :src="require('@/assets/material-didactico/portada-3.jpg')" alt="Una nueva vida" />
						</router-link>
					</div>
				</div>
				<div class="mt-8 lg:mt-0">
					<div class="flex space-x-2 items-center">
						<a href="https://apps.apple.com/us/app/yo-me-quiero-yo-me-cuido/id1608936103" target="_blank" class="block"><img class="h-12" :src="require('@/assets/material-didactico/appstore.png')" alt="App Store" /></a>
						<a href="https://play.google.com/store/apps/details?id=mx.cuentamecomo.yomequiero" target="_blank" class="block"><img class="h-12" :src="require('@/assets/material-didactico/playstore.png')" alt="Play Store" /></a>
					</div>
					<div class="mt-4 text-base max-w-prose mx-auto">
						<p class="text-xl font-light text-gray-500">App con 13 juegos interactivos y un libro descargable en PDF.</p>
					</div>

					<div class="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
						<div class="text-azul-light font-bold text-xl mt-12">¿Qué aprenden?</div>

						<p>El abuso sexual infantil es un problema real, en el que se ha trabajado poco para prevenirlo, muchos adultos se sienten inseguros de cómo hacerlo. En México de acuerdo a la OCDE ( Organización para la cooperación y el desarrollo Económico), ocurren 5.4 millones de abusos infantiles al año. <span class="font-semibold">¿No te parece una cifra alarmante?</span></p>

						<div class="text-cyan font-bold text-xl mt-12">¿Qué podemos hacer?</div>

						<p>Ante estos números, es urgente que padres, escuelas  y maestros emprendamos acciones conjuntamente, encaminadas a la prevención de este peligro para lograr la protección y seguridad de niñas y niños.</p>

						<p>Al crecer, los menores son más independientes y pasan menos tiempo bajo la supervisión de los adultos por lo que debemos darles recursos y  enseñarlos a cuidarse a sí mismos.</p>

						<p>Para atender este problema diseñamos <span class="font-semibold">Yo me quiero yo me cuido</span>, que es una herramienta de apoyo para hablar sobre la importancia del autocuidado y prevención de abuso sexual en forma clara y sencilla para edades de 5 a 8 años. </p>

						<div class="text-verde font-bold text-xl mt-12">¿Cuál es la importancia de hablar de este tema?</div>
						<ul>
							<li>Hablar del abuso sexual con niñas y niños,los protege y  los prepara para  identificar  situaciones de peligro, además de saber actuar ante un posible abuso.</li>
							<li>Aprenden a establecer limites y decir NO ante situaciones que les incomodan.</li>
							<li>Niñas y niños se empoderan al desarrollar la confianza y buena comunicación  pues ahora saben que no están solos y algún adulto cercano podrá defenderlos.</li>
							<li>Por último hablar sobre abuso sexual es un punto más que puede ayudarnos a garantizar una infancia feliz, y por lo tanto una excelente base para una feliz vida adulta.</li>
						</ul>
					</div>
				</div>

				<div class="mt-12 md:hidden flex justify-around">
					<router-link to="/material-didactico/como-soy">
						<img class="rounded-lg shadow-lg h-56 md:h-72 mx-auto" :src="require('@/assets/material-didactico/portada-2.jpg')" alt="¿Cómo soy?" />
					</router-link>
					<router-link to="/material-didactico/una-nueva-vida">
						<img class="rounded-lg shadow-lg h-56 md:h-72 mx-auto" :src="require('@/assets/material-didactico/portada-3.jpg')" alt="Una nueva vida" />
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import { AcademicCapIcon } from '@heroicons/vue/outline'
import Menu from '@/components/core/Menu'

export default {
	components: {
		Menu
	}
}
</script>